<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-card

        >
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- 查询条件     -->
              <!--关联表-->
              <b-col
                      cols="12"
                      md="4"
                      class="mt-1"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="关联表:"
                        label-for="type"
                        label-size="sm"
                >
                  <v-select
                          id="type"
                          :options="typeOptions"
                          :clearable="true"
                          v-model="state.condition.type"
                          :reduce="item => item.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <!--关联单据主表ID-->
              <b-col
                      cols="12"
                      md="4"
                      class="mt-1"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="关联单据主表ID:"
                        label-for="rel_order_id"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.rel_order_id"
                          name="rel_order_id"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="关联单据主表ID"
                  />
                </b-form-group>
              </b-col>

              <!--关联单据明细ID-->
              <b-col
                      cols="12"
                      md="4"
                      class="mt-1"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="关联单据明细ID:"
                        label-for="rel_item_id"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.rel_item_id"
                          name="rel_item_id"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="关联单据明细ID"
                  />
                </b-form-group>
              </b-col>

              <!--入库前库存ID-->
              <b-col
                      cols="12"
                      md="4"
                      class="mt-1"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="入库前库存ID:"
                        label-for="before_stock_id"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.before_stock_id"
                          name="before_stock_id"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="入库前库存ID"
                  />
                </b-form-group>
              </b-col>

              <!--入库后库存ID-->
              <b-col
                      cols="12"
                      md="4"
                      class="mt-1"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="入库后库存ID:"
                        label-for="after_stock_id"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.after_stock_id"
                          name="after_stock_id"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="入库后库存ID"
                  />
                </b-form-group>
              </b-col>

              <!--库存状态-->
              <b-col
                      cols="12"
                      md="4"
                      class="mt-1"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="库存状态:"
                        label-for="stock_status"
                        label-size="sm"
                >
                  <v-select
                          id="stock_status"
                          :options="statusOptions"
                          :clearable="true"
                          v-model="state.condition.stock_status"
                          :reduce="item => item.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>

              <!--入库日志ID-->
              <b-col
                      cols="12"
                      md="4"
                      class="mt-1"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="入库日志ID:"
                        label-for="log_id"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.log_id"
                          name="log_id"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="入库日志ID"
                  />
                </b-form-group>
              </b-col>



              <!--操作按钮-->
              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(type)="data">
          {{ data.item.type===1?'调拨入库':(data.item.type===2?'退货入库':'库位转移') }}
        </template>

        <template #cell(type)="data">
          {{ data.item.stock_status===0?'良品':'不良品' }}
        </template>



      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import stockcreateUseList from './stockcreateUseList'
import stockcreateStore from './stockcreateStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    resetCondition() {
      this.state.condition = {}
      store.commit('stockcreate/updateCondition', this.state.condition)
      this.refetchData()
    },
    searchByCondition() {
      this.refetchData()
    },
  },
  data() {
    return {
      typeOptions: [
        { label: '调拨入库',value:1},
        { label: '退货入库',value:2},
        { label: '库位转移',value:3},
      ],
      statusOptions: [
        { label: '良品',value:0},
        { label: '不良品',value:1},
      ],
    }
  },
  setup() {
    // Register module
    if (!store.hasModule('stockcreate')) store.registerModule('stockcreate', stockcreateStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockcreate')) store.unregisterModule('stockcreate')
    })

    onMounted(() => {
      state.condition = store.getters['stockcreate/getCondition']
    })

    const state = reactive({
      condition: {},
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = stockcreateUseList()

    return {
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
