import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function stockcreateUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    // { key: 'type', label: '1调拨 2销售退货 3库位转移', sortable: true },
    // { key: 'rel_order_id', label: '关联单据id 调拨：allot_outbound_inbound_item.inbound_id', sortable: true },
    // { key: 'rel_item_id', label: '关联单据明细id 调拨：allot_outbound_inbound_item.inbounditem_id', sortable: true },
    // { key: 'before_stock_id', label: '入库前stock_id', sortable: true },
    // { key: 'after_stock_id', label: '入库后stock_id', sortable: true },
    // { key: 'stock_status', label: '库存状态 0良品 1不良品', sortable: true },
    // { key: 'log_id', label: '入库后stock_log表Id', sortable: true },
    //     { key: 'actions', label: '操作' },
    { key: 'id', label: 'ID'},
    { key: 'type', label: '关联表',headerTitle:'1调拨 2销售退货 3库位转移' },
    { key: 'rel_order_id', label: '关联单据主表ID', headerTitle:'关联单据id 调拨：allot_outbound_inbound_item.inbound_id' },
    { key: 'rel_item_id', label: '关联单据明细ID', headerTitle:'关联单据明细id 调拨：allot_outbound_inbound_item.inbounditem_id' },
    { key: 'before_stock_id', label: '入库前库存ID', headerTitle:'入库前stock_id'},
    { key: 'after_stock_id', label: '入库后库存ID', headerTitle:'入库后stock_id'},
    { key: 'stock_status', label: '库存状态', headerTitle:'库存状态 0良品 1不良品'},
    { key: 'qty', label: '入库数量'},
    { key: 'log_id', label: '入库日志ID',headerTitle:'入库后stock_log表Id'},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('sic_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    let  condition = {...store.getters['stockcreate/getCondition']}
    let  params = {
      start: start.value,
      limit: limit.value,
      order_by:  orderBy.value ,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
      moneyStart: condition.money===undefined?'':condition.money.start.valueOf(),
      moneyEnd: condition.money===undefined?'':condition.money.end.valueOf()
    }
    Object.assign(params,condition)
    store
      .dispatch('stockcreate/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
